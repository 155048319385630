import React from "react";
import sleep from "../ultils/sleep";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

const Loading = ({ loading=true, nav="/" }) => {
    const navigate = useNavigate();

    useEffect(()=>{
        if(!loading){
            async function redirect() {
                await sleep(2000);
                navigate(nav);
            }
            redirect(); 
        }
    }, [navigate, loading])

    return (
        <div className="vw-100 vh-100 d-flex justify-content-center align-items-center" style={{ background: "linear-gradient(to right, #a6a4a4, #BFBFBF)" }}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
        
    );
};

export default Loading;