import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Navigation from "./component/navbar";
import Loading from "./page/loading";
import React, { Suspense, lazy } from "react";
import Footer from "./component/Footer";
import NavBarAdminComponent from "./component/navbarAdmin";

const Home = lazy(() => import("./page/home"));
const DetailProduct = lazy(() => import("./page/detailProduct"));
const ListProduct = lazy(() => import("./page/listProduct"));
const ListProductByMerek = lazy(() => import("./page/listProductByMerek"));
const MembershipComponent = lazy(() => import("./page/membership"));
const Promosi = lazy(()=> import("./page/promo"));
const Tips = lazy(()=> import("./page/tips"));
const DetailPromo = lazy(() => import("./page/promo-detail"));
const DetailTips = lazy(() => import("./page/tips-detail"));
const LoginComponent = lazy(()=> import("./page/login"));
const HomePageAdmin = lazy(()=> import("./page/homePageAdmin"));
const AddProduct = lazy(() => import("./page/addProduct"));
const AddDetailProduct = lazy(() => import("./page/AddDetailProduct"));
const AddCategory = lazy(() => import("./page/AddCategory"));
const AddBanner = lazy(()=> import("./page/AddBanner"));
const ListProductAdmin = lazy(() => import("./page/ListProductAdmin"));
const EditProduct = lazy(()=> import("./page/EditProduct"));
const AddPromosi = lazy(() => import("./page/AddPromosi"));
const PromosiAdmin = lazy(()=> import("./page/promoAdmin"));
const TipsAdmin = lazy(()=> import("./page/tipsAdmin"));
const AddTips = lazy(() => import("./page/AddTips"));
const EditCategory = lazy(() => import("./page/editCategory"));
const EditSubCategory = lazy(() => import("./page/editSubCategory"));
const EditBanner = lazy(()=> import("./page/EditBanner"));
const Statistic = lazy(()=> import("./page/statistic"));
const MembershipSee = lazy(() => import("./page/MembershipSee"));
const EditDetailProduct = lazy(() => import("./page/editSubProduct"));
const AddAbout = lazy(() => import("./page/AddAbout"));
const EditMerekComponent = lazy(() => import("./page/editMerek"));

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element:(<Suspense fallback={<Loading />}><Navigation /><Home /> <Footer /> </Suspense>),
    },
    {
      path: "/detail-product",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <DetailProduct />  <Footer /> </Suspense> )
    },
    {
      path: "/products",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <ListProduct />  <Footer /> </Suspense>)
    },
    {
      path: "/merek",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <ListProductByMerek />  <Footer /> </Suspense>)
    },
    {
      path: "/membership",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <MembershipComponent />  <Footer /> </Suspense>)
    },
    {
      path: "/promosi",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <Promosi />  <Footer /> </Suspense>)
    },
    {
      path: "/promo-detail",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <DetailPromo />  <Footer /> </Suspense>)
    },
    {
      path: "/tipsntrick",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <Tips />  <Footer /> </Suspense>)
    },
    {
      path: "/tips-detail",
      element: (<Suspense fallback={<Loading/>}><Navigation /> <DetailTips />  <Footer /> </Suspense>)
    },
    {
      path: "/login",
      element: (<Suspense fallback={<Loading/>}> <LoginComponent /> </Suspense>)
    },
    {
      path: "/admin/homePage",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <HomePageAdmin />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/addProduct",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <AddProduct />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/addProduct/detail",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <AddDetailProduct />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/addCategory",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <AddCategory />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/addBanner",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <AddBanner />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/listProduct",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <ListProductAdmin />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/editProduct",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <EditProduct />  <Footer /> </Suspense>)
    },
    {
      path: "admin/editProduct/detail-product",
      element: (<Suspense fallback={<Loading />}> <NavBarAdminComponent /> <EditDetailProduct />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/edit-category",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <EditCategory />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/editCategory1",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <EditSubCategory />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/addPromosi",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <AddPromosi />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/edit-banner",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <EditBanner />  <Footer /> </Suspense>)
    },  
    {
      path: "/admin/promosi",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <PromosiAdmin />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/statistics",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <Statistic />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/addTips",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <AddTips />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/tips",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <TipsAdmin />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/membership",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <MembershipSee />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/merek",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <EditMerekComponent /> <Footer /> </Suspense>)
    },
    {
      path: "/admin/about",
      element: (<Suspense fallback={<Loading/>}><NavBarAdminComponent /> <AddAbout />  <Footer /> </Suspense>)
    },
    {
      path: "/admin/*",
      element: <Loading loading={false} nav="/admin/homePage"/>
    },
    {
      path: "*",
      element: <Loading loading={false}/>
    }
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
    
  )
}



export default App;
