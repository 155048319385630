import React, { useState } from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logo from '../pngwing.com.png';
import "./css/footer.css";
import axios from 'axios';
import { urlServer, urlServer2 } from '../ultils/variabel';

export default function Footer() {
  const [description, setDescription] = useState("");

  useState(() => {
    async function callApi() {
      try {
        const result = await axios.get(`${urlServer}about`);
        setDescription(result.data.data.data);
      } catch (error) {
        try {
          const result = await axios.get(`${urlServer2}about`)
          setDescription(result.data.data.data);
        } catch (error) {
          setDescription("")
        }
      }
    }
    callApi();
  }, [])

  return (
    <MDBFooter className='text-center text-lg-start text-muted footer'>

      <section className='d-flex justify-content-center justify-content-lg-between border-bottom'>
        <MDBContainer className='text-center textOrange text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                Tentang Kami
              </h6>
              <p>
                {description}
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Sosial Media</h6>
              <p>
              
                <a href='https://bit.ly/WhatsAppSDB' className='text-reset'>
                  <FontAwesomeIcon icon={faWhatsapp} size="lg" /> Whatsapp
                </a>
              </p>
              <p>
                <a href='https://instagram.com/sumadepobangunan?igshid=YmMyMTA2M2Y=' className='text-reset'>
                <FontAwesomeIcon icon={faInstagram} size="lg" /> Instagram
                </a>
              </p>
              <p>
                <a href='https://www.facebook.com/profile.php?id=100089798416232' className='text-reset'>
                  <FontAwesomeIcon icon={faFacebook} size="lg" /> Facebook
                </a>
              </p>
              <p>
                <a href='https://tokopedia.link/sumadepobangunan' className='text-reset'>
                  <img src={logo} alt="icon tokopedia" /> Tokopedia
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" />
                Jl. Martadinata No. 99 Kel. Dendengan Luar Kec. Tikala, Kota Manado
              </p>
              <p>
                <MDBIcon icon="envelope" />
                depobangunansuma2@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" /> +62 823 1782 9922
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center copyRight'>
        © 2023 Copyright:
        <a className='text-reset fw-bold' href='/#'>
          Suma Depo Bangunan.
        </a>  All rights reserved. Engineered by <a className='text-reset fw-bold' href='/#'>Smart Teknologi Labs</a>
      </div>
    </MDBFooter>
  );
}