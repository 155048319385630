import React, { useEffect, useRef, useState } from "react";
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../LOGO DEPO.png';
import "./css/nav.css";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { requestApi } from "../ultils/Request";
const cookies = new Cookies();
const NavBarAdminComponent =  () => {
    const navigate = useNavigate();
    const token = cookies.get("token");
    const verify = async() => {
        const verifikasi = await requestApi("get", "auth", {}, token);
        return verifikasi.verify;
    }
    useEffect(() => {
        if(!token){
            navigate("/");
        }
        verify().then(result => {
            if(!result){
                navigate("/");
            }
        });
        
    }, [token])
    return ( 
        <div className="sticky-top">
            <Navbar expand="lg" className="shadow" style={{ backgroundColor: "#f86c0c", color:"white" }}>
                <Container>
                    <Navbar.Brand href={"/"}>
                        <img src={logo} alt="logo" style={{ width: '150px' }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="/admin/homePage" style={{ color:"#f8f4f4" }}><b> Beranda </b></Nav.Link>
                            <Nav.Link href="/admin/listProduct" style={{ color:"#f8f4f4" }}><b> Produk </b></Nav.Link>
                            <Nav.Link href="/admin/statistics" style={{ color:"#f8f4f4" }}><b> Statistics </b></Nav.Link>
                            <Nav.Link href="/admin/membership" style={{ color:"#f8f4f4" }}><b> Member </b></Nav.Link>
                            <Nav.Link href="/admin/promosi" style={{ color:"#f8f4f4" }}><b> Promosi </b></Nav.Link>
                            <Nav.Link href="/admin/tips" style={{ color:"#f8f4f4" }}><b> Tips & Trick </b></Nav.Link>
                            <NavDropdown title="Tambah" id="addScrollingDropdown">
                                <NavDropdown.Item href="/admin/addCategory">
                                    <b> Tambah Category </b>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/admin/addProduct">
                                    <b> Tambah Product </b>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/admin/addBanner">
                                    <b> Tambah Banner </b>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/admin/addPromosi">
                                    <b> Tambah Promosi </b>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/admin/addTips">
                                    <b> Tambah Tips & Trick </b>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/admin/about">
                                    <b> Ganti Tentang Kami </b>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/admin/merek">
                                    <b> Edit Merek </b>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
     );
}

export default NavBarAdminComponent;