import axios from "axios";
import { imageUrl, urlServer, urlServer2 } from "./variabel";
const defaultCsrfToken = "aqswdefrlpkojihu";

function getToken(token){
    const date = new Date();
    const today = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
    const epochTime = today.getTime() / 1000;
    const csrfToken = `${epochTime + 3600}_${defaultCsrfToken}`;
    return {
        authorization: `Bearer ${token}`,
        csrftoken: `Bearer ${csrfToken}`
    }
}
async function olahMethod(method, url, data, headers){
    let result;
    switch (method.toUpperCase()) {
        case 'GET':
            result = await axios.get(url, {
            headers: headers,
            params: data,
            });
            break;
        case 'POST':
            result = await axios.post(url, data, {
            headers: headers
            });
            break;
        case 'PUT':
            result = await axios.put(url, data, {
            headers: headers
            });
            break;
        case 'DELETE':
            result = await axios.delete(url, {
            headers: headers,
            data: data
            });
            break;
        case 'PATCH':
            result = await axios.patch(url, data, {
            headers: headers
            });
            break;
        }
        return result.data.data;
    }


  export async function requestApi(method, path, data = {}, token = null, isImage = false) {
    let headers = {};
    const url = `${urlServer}${path}`;
  
    if (token) {
      headers = getToken(token);
    }
    let result;
    if(isImage){
      headers["Content-Type"] = "multipart/form-data";
      result = await axios.post(imageUrl, data, {
        headers: headers
      });
      return result.data;
    }
    
    headers["Content-Type"] = "application/json";

    
    try {
      result = await olahMethod(method, url, data, headers);
    } catch (error) {
      try {
        const url2 = `${urlServer2}${path}`;
        result = await olahMethod(method, url2, data, headers);
      } catch (error) {
        return null;
      }
    }
    return result;
  }