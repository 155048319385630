import React, { useEffect, useRef, useState } from "react";
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../LOGO DEPO.png';
import "./css/nav.css";
import { requestApi } from "../ultils/Request";
import { useNavigate } from "react-router-dom";
import sleep from "../ultils/sleep";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { imageUrl } from "../ultils/variabel";
const cookies = new Cookies();
const NavBarComponent =  () => {
    const [display, setDisplay] = useState(false);
    const delayTimeoutRef = useRef(null);
    const navigate = useNavigate();
    const [listItems, setListItems] = useState([]);
    const valurRef = useRef();

    const getNameItemsFromApi = async (nama) => {
        const data = {
            limit: 5,
            filters: {
                name: nama
            }
        }
        const response = requestApi( "Get", 'product/filter/name', data);
        if(!response) return [];
        return response;
    }

    function CekKeranjang(){
        const keranjang = cookies.get("keranjang");
        let html = [];
        let text = "Saya%20tertarik%20dengan%20barang%20ini%0A%0A";
        if(keranjang){
            

            for (let index = 0; index < keranjang.length; index++) {
                const element = keranjang[index];
                text += element.nama +"%20tipe:%20"+ element.tipe.tipe1+"%20variant:%20"+ element.tipe.tipe2+"%20total:%20"  + element.total+"%0A";
                html.push(`
                <hr>
                <div>
                    <img loading="lazy" src="${`https://res.cloudinary.com/dfisv4rjd/image/fetch/w_${240},h_${240},c_fill,f_jpg/${imageUrl}${element.tipe.url}`}" alt="gambar" />
                    <p>Harga Satuan : ${element.hargaSatuan} </p>
                    <p>Jumlah : ${element.total}</p>
                    <p>Harga : ${element.totalHarga}</p>
                </div>
                <hr>
                `);
            }

            text+="%0Aterima%20kasih"
        }else{
            html.push("<p>Keranjang Kosong</p>")
        }
        Swal.fire({
            
            title: '<strong>Keranjang</strong>',
            icon: keranjang ? 'success' : 'question',
            html: html.join(" "),
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            showConfirmButton: keranjang ? true : false,
            confirmButtonText: 'Checkout Product'
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.href = "https://api.whatsapp.com/send?phone=6282317829922&text="+text
            } 
          })
    }

    const handleSearchChange = () => {
        if (delayTimeoutRef.current !== null) {
          clearTimeout(delayTimeoutRef.current);
        }
    
        delayTimeoutRef.current = setTimeout(() => {
          // Kirim permintaan API setelah delay 1 detik
          getNameItemsFromApi(valurRef.current.value).then((result) =>{
            if(result){
                setListItems(result);
            }
          })
        }, 1000);
    };

    useEffect(() => {
        // Bersihkan timer pada saat unmount komponen
        return () => {
          if (delayTimeoutRef.current !== null) {
            clearTimeout(delayTimeoutRef.current);
          }
        };
      }, []);

    return ( 
        <div className="sticky-top">
            <Navbar expand="lg" className="shadow" style={{ backgroundColor: "#f86c0c", color:"white" }}>
                <Container>
                    <Navbar.Brand href={"/"}>
                        <img src={logo} alt="logo" style={{ width: '150px' }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="/" style={{ color:"#f8f4f4" }}><b> Beranda </b></Nav.Link>
                            <Nav.Link href="/products" style={{ color:"#f8f4f4" }}><b> Produk </b></Nav.Link>
                            <Nav.Link href="/merek" style={{ color:"#f8f4f4" }}><b> Merek </b></Nav.Link>
                            <Nav.Link href="/membership" style={{ color:"#f8f4f4" }}><b> Membership </b></Nav.Link>
                            <Nav.Link onClick={() => {
                                CekKeranjang()
                            }} style={{ color:"#f8f4f4" }}><b> Lihat Keranjang </b></Nav.Link>
                            <NavDropdown title="Lebih Banyak" id="navbarScrollingDropdown">
                                <NavDropdown.Item href="/promosi">
                                    <b> Promosi </b>
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/tipsntrick">
                                    <b> Tips & Trik </b>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    <Form onSubmit={(e) => {
                        e.preventDefault()
                        navigate("/products?query=" + valurRef.current.value);
                        window.location.reload(); 
                    }} className="d-flex">
                        <div className="position-relative container">
                            <Form.Control
                            type="search"
                            placeholder="Cari"
                            className="me-2"
                            aria-label="Search"
                            onFocus={()=>{
                                setDisplay(true);
                            }}
                            onBlur={async()=>{
                                await sleep(200);
                                setDisplay(false);
                            }}
                            ref={valurRef}
                            onChange={handleSearchChange}
                            />
                            {display && (
                                <div className="position-absolute bg-white rounded" style={{ width:"90%" }}>
                                <ul className="list-group">
                                    {
                                        listItems.map((v, i) =>{
                                            return <li className="list-group-item list-group-item-action pointer" key={i} 
                                            onClick={()=>{
                                                valurRef.current.value = "";
                                                navigate("/products?query=" + v.name);
                                                window.location.reload(); 
                                                setDisplay(false);
                                            }} >{v.name}</li>
                                        })
                                    }
                                </ul>
                            </div>
                            )}
                        </div>
                    </Form>
                    
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
     );
}

export default NavBarComponent;